import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostLayout from "../../../layouts/post-layout.tsx";
export const _frontmatter = {
  "title": "Setting up a React + TypeScript + SASS + Webpack and Babel project in 6 Steps",
  "path": "/posts/2019-07-17-react-boilerplate",
  "description": "Setting up a React + TypeScript + SASS + Webpack and Babel project in 6 Steps",
  "date": "2019-07-17T00:00:00.000Z",
  "tags": ["React", "TypeScript", "Webpack", "Boilerplate"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PostSeoComponent = makeShortcode("PostSeoComponent");
const PostMetaComponent = makeShortcode("PostMetaComponent");
const AlertComponent = makeShortcode("AlertComponent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PostSeoComponent mdxType="PostSeoComponent" />
    <h1>{`Setting up a React + TypeScript + SASS + Webpack and Babel project in 6 Steps`}</h1>
    <PostMetaComponent mdxType="PostMetaComponent" />
    <AlertComponent level="warning" mdxType="AlertComponent">
This article was published a long time ago. Its content may be outdated and no longer reflects the current state of technology.
    </AlertComponent>
    <p>{`In this tutorial I want to show you how to set up a bulletproof, IE 11 safe, frontend project using TypeScript, React and Sass.`}</p>
    <p>{`Note: I will use `}<a parentName="p" {...{
        "href": "https://yarnpkg.com/"
      }}>{`yarn`}</a>{` instead of npm in this tutorial. If you never heard of it, you should check it out! 😁`}</p>
    <h2>{`Initializing a blank project`}</h2>
    <p>{`Let's start by creating an empty folder and initializing a blank node project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`mkdir awesome-app
cd awesome-app
yarn init
`}</code></pre>
    <p>{`Which will result in the following file structure:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`.
└── package.json
`}</code></pre>
    <h2>{`Installing dependencies`}</h2>
    <p>{`Before we can start to write some of the sweet react code, we first have to set up our transpiration environment. In this case with Webpack and Babel.`}</p>
    <p><em parentName="p">{`But why do I need to transpile my code?`}</em></p>
    <p>{`Well, there are three reasons, why we need to transpile our code:`}</p>
    <ol>
      <li parentName="ol">{`Browsers don’t support TypeScript (at the moment), so we need to transpile it to JavaScript.`}</li>
      <li parentName="ol">{`Not all browsers support modern JavaScript (especially IE). We need to transpile it into an “older version”, so that common browsers can interpret it correctly.`}</li>
      <li parentName="ol">{`We need to transpile our SASS to CSS, because (you might guessed it already) nearly no browser supports SASS.`}</li>
    </ol>
    <p>{`Let’s install all the dependencies we need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add core-js react react-dom regenerator-runtime
yarn add -D  @babel/core @babel/preset-env @babel/preset-react @babel/preset-typescript @types/react @types/react-dom babel-loader css-loader node-sass sass-loader source-map-loader style-loader webpack webpack-cli typescript ts-loader
`}</code></pre>
    <p>{`Event though it looks like a lot of dependencies, we only have 4 of them which are going to be built into our application, the rest are development dependencies that are only required by our development environment.`}</p>
    <p>{`If you are interested, here is a short description of every package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`react  // React framework
react-dom  // React's DOM framework
core-js  // Polyfills for a lot of ECMAScript methods
regenerator-runtime // Polyfill for runtime

/* Webpack */
webpack
webpack-cli

/* Babel core and presets to transpile TypeScript */
@babel/core 
@babel/preset-env 
@babel/preset-react 
@babel/preset-typescript

/* Type Definitions for React */
@types/react 
@types/react-dom babel-loader

/* For transpiling SASS */
style-loader 
css-loader
node-sass
sass-loader

/* For better debugging */
source-map-loader

/* TypeScript core package and Webpack loader */
ts-loader
typescript
`}</code></pre>
    <h2>{`Setting up Webpack`}</h2>
    <p>{`Now that we got all our dependencies installed it is time to create our `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{` file.`}</p>
    <p>{`Webpack will manage our loaders. `}<a parentName="p" {...{
        "href": "https://webpack.js.org/loaders/"
      }}>{`Loaders`}</a>{` are software components (npm packages) that will change/transpile/extract or analyze our code.`}</p>
    <p>{`Our `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{` will look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  mode: "development",
  watch: true,
  entry: "./src/index.tsx",
  output: {
    filename: "bundle.js",
    path: __dirname + "/dist"
  },
  resolve: {
    extensions: [".ts", ".tsx", ".js", ".json"]
  },
  devtool: "source-map",
  module: {
    rules: [
      { test: /\\.scss$/, use: [ "style-loader", "css-loader", "sass-loader" ] },
      { test: /\\.tsx?$/, loader: "babel-loader" },
      { test: /\\.tsx?$/, loader: "ts-loader" },
      { enforce: "pre", test: /\\.js$/, loader: "source-map-loader" }
    ]
  }
};
`}</code></pre>
    <p>{`I think most of the options are self-explanatory, but I want to quickly talk about four of them.`}</p>
    <p><inlineCode parentName="p">{`watch`}</inlineCode>{` states that Webpack will automatically recompile if it noticed a file change aka. `}<em parentName="p">{`cmd + s`}</em>{`.`}</p>
    <p><inlineCode parentName="p">{`devtool: "source-map"`}</inlineCode>{` will add source maps, which will make your developer life a lot easier, since they provide TypeScript sources to your browser devtools, so class names, interfaces and so on don’t get lost. `}<strong parentName="p">{`Important: Remove this option and line 17 for a production build.`}</strong></p>
    <p><inlineCode parentName="p">{`["style-loader", "css-loader", "sass"-loader"]`}</inlineCode>{` is a loader chain. Which basically means that the output (`}<inlineCode parentName="p">{`return`}</inlineCode>{`) from the right loader will be used as the input by the next loader and so on (`}<inlineCode parentName="p">{`right to left!`}</inlineCode>{`). This loader chain will extract SASS from the SASS files, transpile it to CSS and finally to JavaScript.`}</p>
    <p><inlineCode parentName="p">{`babel-loader`}</inlineCode>{` will transpile TypeScript to JavaScript (ES2015 in our case) based on the `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` file which we will configure in the next step.`}</p>
    <p>{`Transpiling is needed because browsers can’t understand TypeScript at the moment, in addition, we need to produce “old” JavaScript to get a good browser coverage.`}</p>
    <h2>{`Setting up Babel`}</h2>
    <p>{`Setting up babel is a piece of cake! Babel will help us transpiling our TypeScript to the right JavaScript standard. This is our `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` config file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets": [
    "@babel/react",
    "@babel/typescript",
    [
      "@babel/env",
      {
        "modules": false,
        "targets": {
          "chrome": "58",
          "ie": "11"
        }
      }
    ]
  ],
}
`}</code></pre>
    <p>{`We basically tell Babel to use it’s React and Typescript presets and to transpile to a minimum version of IE 11 and Chrome 58.`}</p>
    <h2>{`Setting up TypeScript`}</h2>
    <p>{`Hold tight, we are very close to writing our first line in TypeScript, but before we can do that, we need to create a file called `}<inlineCode parentName="p">{`tsconfig.json`}</inlineCode>{`. This file will hold (you might guessed it already) some TypeScript configurations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "compilerOptions": {
    "outDir": "dist/",
    "noImplicitAny": true,
    "module": "commonjs",
    "target": "es2015",
    "jsx": "react"
  },
  "include": [
    "./src/**/*"
  ]
}
`}</code></pre>
    <p>{`I also think that most of the options are clear, but I want to shortly explain two of them:`}</p>
    <p><inlineCode parentName="p">{`noImplicitAny`}</inlineCode>{` is a flag, which will force you to declare typed function arguments. Even though it is not required, I encourage you to use it, because otherwise you can get confused by not knowing which type your function argument was.`}</p>
    <p><inlineCode parentName="p">{`jsx`}</inlineCode>{` states that we want to write `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Web_Components"
      }}>{`web component`}</a>{` syntax in our JavaScript, or in our case TypeScript files.`}</p>
    <p>{`Our project structure is now looking like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`.
├── .babelrc
├── node_modules
├── package.json
├── tsconfig.json
├── webpack.config.js
└── yarn.lock
`}</code></pre>
    <h2>{`Let’s write some React!`}</h2>
    <p>{`Congratulations, you made it! You can start writing React now 🥳`}</p>
    <p>{`Since this article isn’t about writing React, I won’t go into details about my React code, but I want to show you how to use SASS with your React components.`}</p>
    <p>{`Let’s create an `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` in your project root:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html",
        "metastring": "title=index.html",
        "title": "index.html"
      }}>{`<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>Awesome App</title>
  </head>
  <body>
    <div id="app"></div>
    <script src="dist/bundle.js"></script>
  </body>
</html>
`}</code></pre>
    <p>{`Now let’s add some Code!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "title=src/components/Banner/Banner.scss",
        "title": "src/components/Banner/Banner.scss"
      }}>{`@import "../../styles/colors";

.banner {
  background-color: $moccasin;
  box-sizing: border-box;
  padding: 2rem;
  text-align: center;

  &__text {
    color: $dark-red;
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-tsx",
        "metastring": "title=src/components/Banner/Banner.tsx",
        "title": "src/components/Banner/Banner.tsx"
      }}>{`import * as React from "react";
import "./Banner.scss";

interface IProps {
  name: string;
}

export default class Banner extends React.Component<IProps> {
  public render() {
    return (
      <div className="banner">
        <span className="banner__text">
          Hello {this.props.name}!
        </span>
      </div>
    );
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-tsx",
        "metastring": "title=src/index.tsx",
        "title": "src/index.tsx"
      }}>{`import "core-js";
import "regenerator-runtime/runtime";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Banner from "./components/Banner/Banner";
import "./styles/global.scss";

ReactDOM.render(
  <div>
    <Banner name="Max" />
  </div>,
  document.getElementById("app"),
);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "title=src/styles/colors.scss",
        "title": "src/styles/colors.scss"
      }}>{`$moccasin: moccasin;
$dark-red: darkRed;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "title=src/styles/global.scss",
        "title": "src/styles/global.scss"
      }}>{`html {
  font-size: 10px;
}

body {
  font-size: 1.8rem;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`}</code></pre>
    <p>{`To make my life easier I added an Webpack alias to my `}<inlineCode parentName="p">{`package.json`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
"scripts": {    
  "webpack:dev": "npx webpack --config webpack.config.js"
}
...
`}</code></pre>
    <p>{`Now you can run `}<inlineCode parentName="p">{`yarn run webpack:dev`}</inlineCode>{` and open the `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` to see the working app. 🤩`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a939cbdb78eace2e6e99e7e6e3da8ee8/32ac3/app.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.71428571428571%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAABuklEQVQ4y2P4////j58/P3/+9u3rty9fvnwGgy+fv3xFAt++ffuOBP78+fMfDBh+/PrVUFqWEO0VFB0SHZOQkJySmJwaG58YHhkdERUTGR0XFh4Z4B/o6xvg6xvg7x/k4uo+a/ac//////v3j+H1mzfuzm4qyrLyGuoaukamVnamVnZ6xubKGjoqmrpq2voqGjqyCqpSsopSsoqy8sr8QmJ5BUW/f/8GaX769Gl5WUFqYnRWalxaUlRKQkRKQkRaUmRGakxGakx6SnRaclR6cnRGSkxGSkxmalxSXOikCT1fvnwFOfv+g0dvb2z+/2rbv+fb/r/Y9v/l9v8vt/97vvXXlWW/ryz7e3stSOTFNgj692Lb/1db71/a+ebtB4jmh69u7f//5vjfl8f+vTwKRS+O/Hq0/9fD/b+fHPz36ihc/O/Lo/9fH7176cCbt+/Bmu/ff/X6NSQA/hMCEDX37t17/foNTPOrV6Rrfj2qeWhq/vv37z9C4O/fv////7979+6bN+BE8ubNm6NHj27atGkDcWDr1q1nz579+hWcMX7//v38+fObN2/eIA7cvn377du3ECcw/KcAAADwnNKKNmQ+YgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "App screenshot",
            "title": "App screenshot",
            "src": "/static/a939cbdb78eace2e6e99e7e6e3da8ee8/8c557/app.png",
            "srcSet": ["/static/a939cbdb78eace2e6e99e7e6e3da8ee8/4edbd/app.png 175w", "/static/a939cbdb78eace2e6e99e7e6e3da8ee8/13ae7/app.png 350w", "/static/a939cbdb78eace2e6e99e7e6e3da8ee8/8c557/app.png 700w", "/static/a939cbdb78eace2e6e99e7e6e3da8ee8/e996b/app.png 1050w", "/static/a939cbdb78eace2e6e99e7e6e3da8ee8/32ac3/app.png 1249w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I hope you found this tutorial helpful, If you want to take a deeper dive into the code, I will link you the GitHub repo right down below.`}</p>
    <p>{`Cheers, Max.`}</p>
    <p>{`You can find the GitHub repository for this article `}<a parentName="p" {...{
        "href": "https://github.com/maxbause/typescript-react-sass-webpack-babel-boilerplate"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      